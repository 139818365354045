import React from 'react';
import { Link } from 'react-router-dom'

const whatsapp = process.env.REACT_APP_WHATSAPP;

const Pricing = () => {
const pricingData = [
    {
        title: 'Free',
        price: 'Rp. 0,-',
        features: [
            { name: 'Request', value: '10/day' },
            { name: 'Custom Api Key', value: 'No' },
            { name: 'Premium Features', value: 'No' },
            { name: 'VIP Features', value: 'No' },
            { name: 'Expired', value: '-' }
        ],
        link: '/signup',
        linkText: 'Sign Up'
    },
    {
        title: 'Premium',
        price: 'Rp. 10.000,-',
        features: [
            { name: 'Request', value: '200/day' },
            { name: 'Custom Api Key', value: 'Yes' },
            { name: 'Premium Features', value: 'Yes' },
            { name: 'VIP Features', value: 'No' },
            { name: 'Expired', value: '1 Month' }
        ],
        link: whatsapp,
        linkText: 'Order via WhatsApp'
    },
    {
        title: 'VIP',
        price: 'Rp. 25.000,-',
        features: [
            { name: 'Request', value: 'Unlimited' },
            { name: 'Custom Api Key', value: 'Yes' },
            { name: 'Premium Features', value: 'Yes' },
            { name: 'VIP Features', value: 'Yes' },
            { name: 'Expired', value: '1 Month' }
        ],
        link: whatsapp,
        linkText: 'Order via WhatsApp'
    }
];
    return (
        <React.Fragment>
            <div className='container mx-auto'>
                <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3 my-8 mx-8'>
                    {pricingData.map((pricing, index) => (
                        <div key={index} className='rounded-md p-4 bg-color_50 dark:bg-color_900 text-gray-700 dark:text-gray-300 shadow-soft-2xl transition-all ease-in-out duration-300'>
                            <div className='text-center bg-color_500 dark:bg-color_800 text-white rounded-md p-2'>
                                <h1 className='font-semibold text-xl'>{pricing.title}</h1>
                                <h2 className='font-semibold'>{pricing.price}</h2>
                            </div>
                            <div className=''>
                                {pricing.features.map((feature, idx) => (
                                    <div key={idx} className='flex justify-between border-b py-4 border-color_500'>
                                        <p>{feature.name}</p>
                                        <p>{feature.value}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-center mt-4'>
                                <p><Link to={pricing.link} className='text-white font-semibold bg-color_500 dark:bg-color_800 hover:bg-color_400 px-6 py-2 rounded-md'>{pricing.linkText}</Link></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Pricing;
