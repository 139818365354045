import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Loading } from "../Loading.jsx";
import AlertError from "../alert/AlertError.jsx";
import AlertSucess from "../alert/ALertSuccess.jsx";

const Profile = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);

    const [ip, setIp] = useState('0.0.0.0');
    const [users, setUsers] = useState({});
    const [image, setImage] = useState('');
    const [browser, setBrowser] = useState('');
    const [platform, setPlatform] = useState('');
    const { user, sendVerification } = useContext(AuthContext);
    const hiddenApiKey = user.apiKey.replace(/./g, '*');

    const copyApiKey = (apiKey) => {
        navigator.clipboard.writeText(apiKey)
    };


    const handleSendVerification = async () => {
        try {
            setLoading(true);
            const response = await sendVerification(users.email);

            if (response) {
                setSuccessMessage(response);
                setIsSuccess(true);
            } else {
                setErrorMessage("Failed to send verification email. Please try again later.");
                setIsError(true);
            }
        } catch (error) {
            setErrorMessage("Failed to send verification email. Please try again later.");
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setIp(user.ip);
        setBrowser(navigator.appName);
        setPlatform(navigator.platform);
        setLoading(false);
        setUsers(user);
        setImage(user.imageData.data);
    }, [user]);

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setIsSuccess(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
        if (isError) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setIsError(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isSuccess, isError]);
    return (
        <React.Fragment>
            {loading && (<Loading load={loading} />)}
            {isSuccess && (<AlertSucess message={successMessage} />)}
            {isError && (<AlertError message={errorMessage} />)}
            <div className="relative">
                <div
                    className="h-48 bg-cover bg-center rounded-t-xl"
                    style={{ backgroundImage: "url('/img/curved14.jpg')" }}
                ></div>

                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-3/4 md:translate-y-1/2 w-11/12 md:w-3/4 lg:w-10/12 xl:w-11/12 bg-color_50 dark:bg-color_900 rounded-xl shadow-lg flex items-center gap-4 p-4">
                    <div className="w-full block md:flex md:justify-between md:items-center">
                        <div className="block md:flex md:justify-center md:items-center lg:justify-start gap-4 w-full">
                            <div className='flex justify-center items-center'>
                                <img
                                    src={image}
                                    alt={users.username}
                                    className="h-24 w-24 rounded-lg object-cover mb-4 md:mb-0"
                                />
                            </div>
                            <div className="text-center md:text-left text-gray-700 dark:text-gray-300">
                                <h1 className="text-xl font-semibold">{users.name}</h1>
                                <p className="text-gray-600 dark:text-gray-400">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                    {users.email}
                                </p>
                            </div>
                        </div>
                        <div className="block lg:flex lg:justify-center lg:items-center gap-3 p-4 w-full">
                            <p className="text-sm text-gray-600 dark:text-gray-300 w-full shadow-soft-2xl">
                                <Link to='/pricing' className='text-sm flex items-center justify-center px-4 py-2 bg-gradient-to-tl from-color_400 to-color_500 dark:from-color_800 dark:to-color_700 text-white rounded-md shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer uppercase'>{user.type}</Link>
                            </p>
                            <Link to='/profile/setting' className="bg-gray-50 dark:bg-color_800 text-gray-800 dark:text-gray-200 py-2 px-4 rounded-lg flex items-center w-full my-2 justify-center shadow-soft-2xl">
                                <FontAwesomeIcon icon={faCog} className="mr-2" />
                                Settings
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-64 md:mt-24 lg:mt-20 mx-auto p-8 bg-color_50 dark:bg-color_900 text-gray-700 dark:text-gray-300 rounded-lg shadow-soft-2xl'>
                <h1 className='mb-4 text-xl'>Detail Profile</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
                    <div className="mb-4">
                        <p className="font-light text-sm">Name:</p>
                        <p>{users.name}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Username:</p>
                        <p>{users.username}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Email:</p>
                        <p>{users.email}{!users.verify && (
                            <button
                                onClick={handleSendVerification}
                                className="text-xs ml-2 px-2 py-1 text-red-600 border border-red-600 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 dark:text-gray-200 rounded-md  hover:text-white transition duration-300"
                            >
                                Verify
                            </button>
                        )}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Phone Number:</p>
                        <p>{users.phone}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Premium:</p>
                        <p>{users.premium ? "Yes" : "No"}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Expired Date:</p>
                        <p>{users.expiredDate === null ? "-" : new Date(users.expiredDate).toLocaleString()}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Total Request:</p>
                        <p>{users.totalRequest}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Request Today:</p>
                        <p>{users.requestDay}/{users.type === 'free' ? 20 : user.type === 'premium' ? 100 : 0}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Your IP Address:</p>
                        <p>{ip}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Browser:</p>
                        <p>{browser}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Platform:</p>
                        <p>{platform}</p>
                    </div>
                    <div className="mb-4">
                        <p className="font-light text-sm">Apikey:</p>
                        <p>{hiddenApiKey}<button
                            onClick={() => copyApiKey(users.apiKey)}
                            className="text-xs ml-2 px-2 py-1 text-gray-600 dark:text-gray-300 border border-gray-600 dark:border-gray-300 rounded-md hover:bg-gray-600 hover:text-white transition duration-300"
                        >
                            <i className="fas fa-copy"></i>
                        </button>
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default Profile;
