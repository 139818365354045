import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState(() => {
        const userData = localStorage.getItem('user');
        return userData ? JSON.parse(userData) : null;
    });

    const validateSignupInput = (email, phone, password, name, username) => {
        if (!email || !phone || !password || !name || !username) {
            throw new Error('All fields are required.');
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            throw new Error('Invalid email format.');
        }

        const phoneRegex = /^[0-9]{10,15}$/;
        if (!phoneRegex.test(phone)) {
            throw new Error('Phone number must be between 10 and 15 digits.');
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(password)) {
            throw new Error('Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and special characters.');
        }

        const nameRegex = /^[A-Za-z\s]+$/;
        if (!nameRegex.test(name)) {
            throw new Error('Name can only contain letters and spaces.');
        }

        const usernameRegex = /^[a-zA-Z0-9_-]{3,20}$/;
        if (!usernameRegex.test(username)) {
            throw new Error('Username must be 3-20 characters long and can only contain letters, numbers, underscores, and dashes.');
        }
    };

    const signup = async (email, phone, password, name, username) => {
        try {
            validateSignupInput(email, phone, password, name, username);

            const response = await axiosInstance.post('/auth/users/register', { email, phone, password, name, username });

            if (!response.data) {
                throw new Error('Signup failed. No response data.');
            } else {
                return "Sign Up Success"
            }
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                const data = error.response.data;

                let errorMessage = 'Signup failed.';

                if (status === 400) {
                    errorMessage = `Signup error: ${data.message || 'Bad Request'}`;
                } else if (status === 401) {
                    errorMessage = 'Signup error: Unauthorized. Please check your credentials.';
                } else if (status === 403) {
                    errorMessage = 'Signup error: Forbidden. You do not have permission to perform this action.';
                } else if (status === 404) {
                    errorMessage = 'Signup error: Endpoint not found. Please check the URL.';
                } else if (status === 409) {
                    errorMessage = `Signup error: ${data.message || 'Conflict. The user may already exist.'}`;
                } else if (status === 500) {
                    errorMessage = 'Signup error: Internal server error. Please try again later.';
                } else {
                    errorMessage = `Signup error: ${status} ${error.response.statusText}`;
                }

                throw new Error(errorMessage);
            } else if (error.request) {
                throw new Error('Signup error: No response from server. Please check your internet connection.');
            } else {
                throw new Error(`Signup error: ${error.message}`);
            }
        }
    };

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/auth/users/login', { username, password });

            if (response.data.token) {
                const token = response.data.token;
                setToken(token);
                localStorage.setItem('token', token);
                return "Login success"
            } else {
                throw new Error('Login failed. No token received.');
            }
        } catch (error) {
            let errorMessage = 'Login failed. Please try again.';

            if (error.response) {
                const status = error.response.status;
                const data = error.response.data;

                if (status === 400) {
                    errorMessage = 'Bad request. Please check the input fields.';
                } else if (status === 401) {
                    errorMessage = 'Invalid username or password.';
                } else if (status === 404) {
                    errorMessage = 'User not found. Please check your username.';
                } else if (status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                } else if (data && data.message) {
                    errorMessage = data.message;
                }
            } else if (error.request) {
                errorMessage = 'No response from server. Please check your internet connection.';
            }

            throw new Error(errorMessage);
        }
    };

    const updateUser = async (userData) => {
        try {
            const decodedToken = jwtDecode(token);
            const response = await axiosInstance.put(`/auth/users/${decodedToken.id}`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return 'Success Update Data!'
        } catch (error) {
            console.error('Error updating user:', error.message);
            throw new Error('Failed to update user.');
        }
    };

    const checkApikey = async (apiKey) => {
        try {
            const response = await axiosInstance.post(`/dashboard/check`, { apiKey: apiKey });

            return response.data.message
        } catch (error) {
            throw new Error('Apikey Notfound');
        }
    };

    const sendVerification = async (email) => {
        try {
            const response = await axiosInstance.post(`/auth/send-verification-email`, { email: email });

            if (response && response.data) {
                return response.data.message;
            }
        } catch (error) {
            throw new Error("Please wait 30 minutes before sending another verification email.");
        }
    };

    useEffect(() => {
        const handleVerifyToken = async (token) => {
            try {
                const decodedToken = jwtDecode(token);
                const response = await axiosInstance.get(`/auth/users/${decodedToken.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data) {
                    setUser(response.data);
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
            } catch (error) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setToken(null);
                setUser(null);
            }
        };

        if (token) {
            handleVerifyToken(token);
        }
    }, [token]);

    const logout = async () => {
        try {
            setUser(null);
            localStorage.removeItem('user');

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found. User might already be logged out.');
            }

            const response = await axiosInstance.post('/auth/users/logout', null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status !== 200) {
                throw new Error('Logout failed. Server responded with status ' + response.status);
            }

            setToken(null);
            localStorage.removeItem('token');
        } catch (error) {
            let errorMessage = 'Logout error.';

            if (error.response) {
                const status = error.response.status;
                const data = error.response.data;

                if (status === 401) {
                    errorMessage = 'Logout error: Unauthorized. Invalid token.';
                } else if (status === 403) {
                    errorMessage = 'Logout error: Forbidden. You do not have permission to perform this action.';
                } else if (status === 500) {
                    errorMessage = 'Logout error: Internal server error. Please try again later.';
                } else {
                    errorMessage = `Logout error: ${status} ${error.response.statusText}`;
                    if (data && data.message) {
                        errorMessage += ` - ${data.message}`;
                    }
                }
            } else if (error.request) {
                errorMessage = 'Logout error: No response from server. Please check your internet connection.';
            } else {
                errorMessage = `Sesi Anda Habis. Login lagi :(`;
            }
            setUser(null);
            localStorage.removeItem('user');
            setToken(null);
            localStorage.removeItem('token');

            console.error(errorMessage);
            return (<Navigate to='/login'/>)
        }
    };

    const fetchAllFeatures = async (token) => {
        try {
            const response = await axiosInstance.get(`/features`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            console.error('Error fetching features:', error.message);
            throw new Error('Failed to fetch all features.');
        }
    };

    return (
        <AuthContext.Provider value={{ token, signup, user, login, logout, updateUser, checkApikey, sendVerification, fetchAllFeatures }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
