import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';

const Header = () => {
    const { user, logout } = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScrollToElement = () => {
            if (window.location.hash) {
                const element = document.querySelector(window.location.hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        handleScrollToElement();

        const handleInternalNavigation = () => {
            handleScrollToElement();
        };

        window.addEventListener('hashchange', handleInternalNavigation);

        return () => {
            window.removeEventListener('hashchange', handleInternalNavigation);
        };
    }, []);

    return (
        <nav className='mb-2'>
            <div className='container mx-auto px-4 py-4 flex items-center justify-between'>
                <div>
                    <Link to='/' className='text-xl font-bold'>
                        dobda<span className="text-color_500">api.</span>
                    </Link>
                </div>
                <div className='hidden lg:flex items-center space-x-4'>
                    <Link to='/#home' className='hover:text-color_500'>
                        Home
                    </Link>
                    <Link to='/#pricing' className='hover:text-color_500'>
                        Pricing
                    </Link>
                    <Link to='/#about' className='hover:text-color_500'>
                        About
                    </Link>
                </div>
                <div className='hidden lg:flex items-center space-x-3'>
                    {user ? (
                        <button onClick={logout} className='px-3 hover:text-color_500 flex items-center'>
                            <img src='logo/log-out.svg' alt='Logout' className='w-5 h-5 mr-2' />
                            Logout ({user.username})
                        </button>
                    ) : (
                        <>
                            <Link to="/login" className='px-3 hover:text-color_500 flex items-center'>
                                <img src='logo/log-in.svg' alt='Login' className='w-5 h-5 mr-2' />
                                Login
                            </Link>
                            <Link to="/signup" className='px-3 py-2 border-2 rounded-md border-color_300 hover:border-color_500 bg-color_500 text-color_50 hover:bg-transparent transition-all ease-in duration-300 hover:text-gray-900'>
                                Sign Up
                            </Link>
                        </>
                    )}
                </div>
                <div className='lg:hidden'>
                    <button onClick={toggleMenu} className='text-gray-500 hover:text-color_500 focus:outline-none'>
                        <svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d={isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
                        </svg>
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <div className='lg:hidden flex justify-center text-center'>
                    <div className='px-4 pt-2 pb-3 space-y-1'>
                        <Link to='/#home' className='block text-base font-medium text-gray-700 hover:text-color_500 py-2'>
                            Home
                        </Link>
                        <Link to='/#pricing' className='block text-base font-medium text-gray-700 hover:text-color_500 py-2'>
                            Pricing
                        </Link>
                        <Link to='/#about' className='block text-base font-medium text-gray-700 hover:text-color_500 py-2'>
                            About
                        </Link>
                        {user ? (
                            <button onClick={logout} className='w-full text-left px-3 py-2 text-base font-medium text-gray-700 hover:text-color_500 flex items-center'>
                                <img src='logo/log-out.svg' alt='Logout' className='w-5 h-5 mr-2' />
                                Logout ({user.username})
                            </button>
                        ) : (
                            <>
                                <Link to="/login" className='text-center px-3 py-2 text-base font-medium text-gray-700 hover:text-color_500 flex items-center'>
                                    <img src='logo/log-in.svg' alt='Login' className='w-5 h-5 mr-2' />
                                    Login
                                </Link>
                                <Link to="/signup" className='block px-3 py-2 mt-2 text-center border-2 rounded-md border-color_300 hover:border-color_500 bg-color_500 text-color_50 hover:bg-transparent transition-all ease-in duration-300 hover:text-gray-900'>
                                    Sign Up
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Header;
