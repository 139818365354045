import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from '@fortawesome/free-solid-svg-icons';
const domain = process.env.REACT_APP_DOMAIN;

const FeatureTable = ({ features }) => {
    const { user } = useContext(AuthContext);
    const requestMethodClasses = {
        GET: 'bg-green-500 text-gray-100',
        POST: 'bg-blue-700 text-blue-100',
        PUT: 'bg-yellow-700 text-yellow-100',
        DELETE: 'bg-red-700 text-red-100',
        PATCH: 'bg-purple-700 text-purple-100'
    };

    const RequestMethodBadge = ({ method }) => {
        const classNames = `px-2 py-1 text-xs rounded mt-1 ${requestMethodClasses[method]}`;
        return <span className={classNames}>{method}</span>;
    };
    return (
        <React.Fragment>
            <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-color_50 dark:bg-color_900 border-0 border-transparent border-solid shadow-soft-xl rounded-lg bg-clip-border">
                <div className="flex-auto px-0 pt-0 pb-2">
                    <div className="p-0 overflow-x-auto">
                        <table className="items-center w-full mb-0 align-top border-gray-200  text-gray-600 dark:text-gray-400 table-striped">
                            <thead className="align-bottom font-light">
                                <tr>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">No</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Feature Name</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Category</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Request Method</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Description</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Query Parameter</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Status</th>
                                    <th className="px-6 py-3 font-medium text-left uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {features.map((feature, index) => (
                                    <tr key={index}>
                                        <td className="p-2 align-middle bg-transparent text-sm shadow-transparent text-center">
                                            <span className="leading-tight text-sm">{index + 1}</span>
                                        </td>
                                        <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                            <span className="leading-tight text-sm">{feature.featureName}</span>
                                        </td>
                                        <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                            <span className="leading-tighttext-sm">{feature.category}</span>
                                        </td>
                                        <td className="text-sm">
                                            <div className="flex flex-wrap gap-2">
                                                {feature.requestMethod.slice(0, 2).map((method) => (
                                                    <RequestMethodBadge key={method} method={method} />
                                                ))}
                                            </div>
                                            {feature.requestMethod.length > 2 && (
                                                <div className="flex flex-wrap gap-2 mt-2">
                                                    {feature.requestMethod.slice(2).map((method) => (
                                                        <RequestMethodBadge key={method} method={method} />
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                        <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                            <span className="leading-tighttext-sm">{feature.description}</span>
                                        </td>
                                        <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                            <span className="leading-tighttext-sm">{feature.queryParameter}</span>
                                        </td>
                                        <td className="text-center p-2 align-middle bg-transparent text-sm shadow-transparent">
                                            <span className={`px-3 py-1 text-xs rounded ${feature.status.toLowerCase() === 'active' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                                {feature.status}
                                            </span>
                                        </td>
                                        <td className="text-center flex gap-2 text-xs items-center justify-center p-2 text-gray-100">
                                            {feature.status === 'active' ? (
                                                <Link to={`${domain}/api/${feature.url.replace("YOUR_APIKEY", user.apiKey)}`} className="px-2 py-1 rounded-lg shadow-soft-2xl bg-green-500">
                                                    <FontAwesomeIcon icon={faPlay} />
                                                </Link>
                                            ) : (
                                                <button type='button' className="px-2 py-1 rounded-lg shadow-soft-2xl bg-red-500 text-white" disabled>
                                                    <FontAwesomeIcon icon={faPlay} />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default FeatureTable;
