import React from 'react';

function AlertModal({ onClose, message }) {
    return (
        <div className={`fixed inset-0 backdrop-blur-md flex items-center justify-center`}>
            <div className='bg-color_50 border-2 border-gray-200 p-6 rounded-lg shadow-lg max-w-sm w-full'>
                <div className="mb-4">
                    <h2 className="text-xl font-bold text-red-700">Alert</h2>
                    <p>{message}</p>
                </div>
                <div className="text-right text-color_500">
                    <button
                        onClick={onClose}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AlertModal;