import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import AlertSucess from '../alert/ALertSuccess';
import AlertError from '../alert/AlertError';

const EditProfileForm = () => {
    const navigate = useNavigate();
    const { user, updateUser } = useContext(AuthContext);

    const [formData, setFormData] = useState({
        name: user.name,
        username: user.username,
        email: user.email,
        phone: user.phone || '',
    });

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorPassMessage, setErrorPassMessage] = useState('');
    const [isUpdate, setUpdate] = useState(false);
    const [isUpdateMsg, setUpdateMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [nameError, setNameError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        switch (name) {
            case 'name':
                setNameError(value.length < 3 ? 'Name must be at least 3 characters' : '');
                break;
            case 'username':
                setUsernameError(value.length < 3 ? 'Username must be at least 3 characters' : '');
                break;
            case 'email':
                setEmailError(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ? '' : 'Invalid email format');
                break;
            case 'phone':
                setPhoneError(value.length < 10 ? 'Phone number must be at least 10 digits' : '');
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nameError || usernameError || emailError || phoneError) {
            setErrorMsg('Please fill in all fields correctly');
            setIsError(true);
        } else {
            try {
                const res = await updateUser(formData);
                setUpdateMsg(res);
                setUpdate(true);
            } catch (error) {
                setErrorMsg('Failed to update user.');
                setIsError(true);
            }
        }
    };

    const handleSubmitPass = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match!');
        } else {
            setErrorMessage('');
            setErrorPassMessage('');

            try {
                await updateUser({ password: newPassword });
                setUpdateMsg('Password changed successfully!');
                setUpdate(true);
            } catch (error) {
                setErrorMsg('Failed to update user.');
                setIsError(true);
            }
        }
    };

    useEffect(() => {
        if (isUpdate) {
            const timer = setTimeout(() => {
                setUpdateMsg('');
                setUpdate(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
        if (isError) {
            const timer = setTimeout(() => {
                setErrorMsg('');
                setIsError(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isUpdate, isError]);

    return (
        <React.Fragment>
            {isUpdate && (<AlertSucess message={isUpdateMsg} />)}
            {isError && (<AlertError message={errorMsg} />)}
            <form onSubmit={handleSubmit} className="container mx-auto px-4 py-8 bg-color_50 dark:bg-color_900 rounded-lg shadow-xl">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="md:col-span-1 flex flex-col items-center justify-center rounded-lg p-2">
                        <img
                            src={user.imageData.data === 'img/avatar.jpeg' ? '/img/avatar.jpeg' : user.imageData.data}
                            alt={user.username}
                            className="h-64 w-64 object-cover rounded-full shadow-lg"
                        />
                    </div>

                    <div className="md:col-span-2 rounded-lg p-4">
                        <h2 className="text-xl text-gray-700 dark:text-gray-400 mb-4">Edit User Profile</h2>
                        <table className="table-auto w-full text-gray-700 dark:text-gray-300">
                            <tbody>
                                <tr>
                                    <td className="font-semibold pr-2">Name</td>
                                    <td>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="border border-gray-300 dark:border-gray-600 bg-color_100 dark:bg-color_800 rounded-md p-2 w-full"
                                            required
                                        />
                                        {nameError && <p className='text-red-600 dark:text-gray-300'>{nameError}</p>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-semibold pr-2">Username</td>
                                    <td>
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                            className="border border-gray-300 dark:border-gray-600 bg-color_100 dark:bg-color_800 rounded-md p-2 w-full"
                                            required
                                        />
                                        {usernameError && <p className='text-red-600 dark:text-gray-300'>{usernameError}</p>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-semibold pr-2">Email</td>
                                    <td>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="border border-gray-300 dark:border-gray-600 bg-color_100 dark:bg-color_800 rounded-md p-2 w-full"
                                            required
                                        />
                                        {emailError && <p className='text-red-600 dark:text-gray-300'>{emailError}</p>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-semibold pr-2">Phone Number</td>
                                    <td>
                                        <input
                                            type="number"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className="border border-gray-300 dark:border-gray-600 bg-color_100 dark:bg-color_800 rounded-md p-2 w-full dark:focus:bg-color_700"
                                        />
                                        {phoneError && <p className='text-red-600 dark:text-gray-300'>{phoneError}</p>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex justify-center gap-3 mt-4">
                            <button
                                type="submit"
                                className="text-sm flex items-center justify-center px-4 py-2 bg-gradient-to-tl from-color_400 to-color_500 dark:from-color_800 dark:to-color_700 text-white rounded-md shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="text-sm flex items-center justify-center px-4 py-2 bg-gradient-to-tl from-gray-600 to-gray-500 dark:from-gray-600 dark:to-gray-800 text-white rounded-md shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <div className="container mx-auto bg-color_50 dark:bg-color_900 rounded-lg shadow-soft-2xl w-full p-8 my-4">
                <h2 className="text-2xl mb-6 text-gray-700 dark:text-gray-300">Change Password</h2>
                <form onSubmit={handleSubmitPass}>
                    <div className='block md:flex justify-between w-full gap-4'>
                        <div className="mb-4 w-full">
                            <label htmlFor="current-password" className="block text-gray-700 dark:text-gray-300 mb-2">Current Password:</label>
                            <input
                                type="password"
                                id="current-password"
                                className="w-full px-3 py-2 border rounded-lg dark:bg-color_800 bg-color_100 shadow-soft-sm"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                            />
                            {errorPassMessage && <div className="text-red-500 mt-2">{errorPassMessage}</div>}
                        </div>
                        <div className="mb-4 w-full">
                            <label htmlFor="new-password" className="block text-gray-700 dark:text-gray-300 mb-2">New Password:</label>
                            <input
                                type="password"
                                id="new-password"
                                className="w-full px-3 py-2 border rounded-lg dark:bg-color_800 bg-color_100 shadow-soft-sm"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4 w-full">
                            <label htmlFor="confirm-password" className="block text-gray-700 dark:text-gray-300 mb-2">Confirm New Password:</label>
                            <input
                                type="password"
                                id="confirm-password"
                                className="w-full px-3 py-2 border rounded-lg dark:bg-color_800 bg-color_100 shadow-soft-sm"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
                        </div>

                    </div>
                    <div className="flex justify-center lg:justify-end">
                        <button
                            type="submit"
                            className="text-sm flex items-center justify-center px-4 py-2 bg-gradient-to-tl from-color_400 to-color_500 dark:from-color_800 dark:to-color_700 text-white rounded-md shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                        >
                            Change Password
                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default EditProfileForm;
