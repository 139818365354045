import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const RedirectIfAuthenticated = ({ children }) => {
    const { user } = useContext(AuthContext);
    return user ? <Navigate to="/docs" replace /> : children;
};

export default RedirectIfAuthenticated;
