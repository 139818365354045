import React, { useState, useEffect } from "react";
import Sidebar from "../partial/Sidebar.jsx";
import Navbar from "../partial/Header.jsx";
import Footer from "../partial/Footer.jsx";

const Layout = ({ children, title }) => {
    const [sidebar, setSidebar] = useState(false);

    const [darkMode, setDarkMode] = useState(
        localStorage.getItem('darkMode') === 'true'
    );

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const toggleSidebar = () => {
        setSidebar(!sidebar);
    };

    useEffect(() => {
        setSidebar(false);
    }, [children]);

    return (
        <React.Fragment>
            <div className={`${darkMode ? 'dark' : ''}`}>
                <div className="flex h-screen bg-color_100 dark:bg-color_1100 transition-all duration-500">
                    <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
                    <div className="flex flex-col min-h-screen flex-1 overflow-y-auto border-l border-gray-300 dark:border-color_900">
                        <Navbar toggleDarkMode={toggleDarkMode} toggleSidebar={toggleSidebar} darkMode={darkMode} title={title} />
                        <main className="flex-1 p-4">
                            {children}
                        </main>
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Layout;
