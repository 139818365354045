import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";

const whatsapp = process.env.REACT_APP_WHATSAPP;
const instagram = process.env.REACT_APP_INSTAGRAM;
const github = process.env.REACT_APP_GITHUB;

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="dark:bg-color_1100 dark:text-gray-100 p-4 block sm:flex sm:justify-between sm:items-center text-gray-700 trasit ease-in-out duration-500">
                <p className="text-center sm:text-left">&copy; 2024, made with ❤️ by <Link to={github}><strong className="text-color_500">Irwanx</strong></Link>.</p>
                <div className="flex justify-center sm:justify-end text-xl gap-4">
                    <Link to={github}><FontAwesomeIcon icon={faGithub} className="hover:text-gray-900 dark:hover:text-gray-50" /></Link>
                    <Link to={whatsapp}><FontAwesomeIcon icon={faWhatsapp} className="hover:text-green-600" /></Link>
                    <Link to={instagram}><FontAwesomeIcon icon={faInstagram} className="hover:text-pink-500" /></Link>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer