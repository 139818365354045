import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext.js';
import RedirectIfAuthenticated from './context/RedirectIfAuthenticated.js';
import PrivateRoute from './context/PrivateRoute.js';

import Login from './components/site/Login.jsx';
import Signup from './components/site/Signup.jsx';
import HomePage from './components/site/HomePage.jsx';
import NotFound from './components/site/NotFound.jsx';

import Profile from './components/profile/Profile.jsx';
import ProfileSetting from './components/profile/ProfileSetting.jsx';

import Docs from './components/Docs.jsx';
import Pricing from './components/Pricing.jsx';

import Layout from './components/layout/Layout.jsx';

import RouterFeature from './components/features/Index.jsx';
import axiosInstance from './axiosInstance.js';

import { FeaturesProvider } from './context/FeatureContext.js';

const App = () => {
  return (
    <AuthProvider>
      <FeaturesProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage axiosInstance={axiosInstance} />} />
            <Route path="/docs" element={<PrivateRoute><Layout title="Dashboard"><Docs axiosInstance={axiosInstance} /></Layout></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Layout title="Profile" ><Profile /></Layout></PrivateRoute>} />
            <Route path="/profile/setting" element={<PrivateRoute><Layout title="Update Profile" ><ProfileSetting /></Layout></PrivateRoute>} />
            <Route path="/pricing" element={<PrivateRoute><Layout title="Pricing" ><Pricing /></Layout></PrivateRoute>} />
            <Route
              path="/login"
              element={
                <RedirectIfAuthenticated>
                  <Login axiosInstance={axiosInstance} />
                </RedirectIfAuthenticated>
              }
            />
            <Route
              path="/signup"
              element={
                <RedirectIfAuthenticated>
                  <Signup />
                </RedirectIfAuthenticated>
              }
            />
            <Route path="/:features/*" element={<PrivateRoute><RouterFeature /></PrivateRoute>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </FeaturesProvider>
    </AuthProvider>
  );
};

export default App;
