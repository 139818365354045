import React, { useContext } from "react";
import { AuthContext } from '../../context/AuthContext.js';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faWallet, faList, faX, faArrowRightFromBracket, faDownload, faMagnifyingGlass, faHeadSideVirus/*, faInfo, faFileImage*/ } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const whatsapp = process.env.REACT_APP_WHATSAPP;
const instagram = process.env.REACT_APP_INSTAGRAM;
const github = process.env.REACT_APP_GITHUB;

const Sidebar = ({ sidebar, toggleSidebar }) => {
    const { user, logout } = useContext(AuthContext);
    const location = useLocation();

    const sidebarCategories = [
        {
            title: null,
            links: [
                { icon: faHome, text: 'Dashboard', to: '/docs' },
                { icon: faWallet, text: 'Pricing', to: '/pricing' }
            ]
        },
        {
            title: 'Feature',
            links: [
                { icon: faList, text: 'All Features', to: '/features/all' },
                { icon: faDownload, text: 'Downloader', to: '/features/downloader' },
                { icon: faHeadSideVirus, text: 'Artificial Intelligence', to: '/features/artificial-intelligence' },
                { icon: faMagnifyingGlass, text: 'Searcher', to: '/features/searcher' },
                // { icon: faFileImage, text: 'Image Processing', to: '/features/imageprocessing' },
            ]
        },
        {
            title: 'Contact Me',
            links: [
                { icon: faGithub, text: 'Github', to: github },
                { icon: faWhatsapp, text: 'WhatsApp', to: whatsapp },
                { icon: faInstagram, text: 'Instagram', to: instagram },
            ]
        }
    ];
    
    return (
        <React.Fragment>
            <aside className={`${sidebar ? 'fixed translate-x-0' : 'hidden'} transition-all duration-500 ease-in-out lg:block p-4 w-3/4 sm:w-4/12 md:w-2/5 lg:w-1/5 bg-color_100 dark:bg-color_1100 z-40 min-h-full`}>
                {sidebar && (
                    <div className="flex justify-end text-sm">
                        <button onClick={toggleSidebar} className="text-gray-700 dark:text-gray-300">
                            <FontAwesomeIcon icon={faX} />
                        </button>
                    </div>
                )}
                <div className={`my-2 flex justify-center`}>
                    <Link to='/' className="text-center font-semibold text-gray-700 dark:text-gray-200">
                        <div className="flex items-center justify-center">
                            <img src="/logo/logo.jpeg" alt="logo dobdabot" className="h-8 w-8 rounded-full" />
                        </div>
                        <span>Dobda</span>
                        <span className="text-color_500 dark:text-color_400">Apis</span>
                    </Link>
                </div>
                <hr className="border-b border-gray-300 dark:border-color_900 mb-4" />
                <div className="px-2 lg:px-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 16rem)' }}>
                    {sidebarCategories.map((category, index) => (
                        <div key={index}>
                            {category.title && (
                                <span className="text-color_500 uppercase font-semibold text-xs text-center my-4 dark:text-gray-300">{category.title}</span>
                            )}
                            <table className="mx-auto w-full" style={{ maxHeight: '100%' }}>
                                <tbody>
                                    {category.links.map((link, subIndex) => (
                                        <tr key={subIndex} className={`group hover:bg-color_50 text-sm ${link.to === location.pathname ? 'bg-color_50 dark:bg-color_900 shadow-soft-2xl' : ''}`}>
                                            <td className="w-12 text-center py-3 px-2 rounded-l-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                                <div className="flex items-center justify-center w-8 h-8 bg-gradient-to-tl from-color_400 to-color_500 dark:from-color_800 dark:to-color_700 text-white rounded-lg shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer">
                                                    <FontAwesomeIcon icon={link.icon} />
                                                </div>
                                            </td>
                                            <td className="p-3 text-gray-700 dark:text-gray-100 font-medium rounded-r-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                                {link.to.startsWith('http') ? (
                                                    <a href={link.to} className="block" target="_blank" rel="noopener noreferrer">
                                                        {link.text}
                                                    </a>
                                                ) : link.to.startsWith('#') ? (
                                                    <button onClick={link.onclick}>{link.text}</button>
                                                ) : (
                                                    <Link to={link.to} className="block">
                                                        {link.text}
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
                <div className="px-2 lg:px-6 my-1">
                    <div>
                        <table className="mx-auto w-full" style={{ maxHeight: '100%' }}>
                            <tbody>
                                <tr className="group text-sm">
                                    <td className="w-12 text-center py-3 px-2 rounded-l-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                        <div className="flex items-center justify-center w-8 h-8 bg-gradient-to-tl from-color_50 to-color_50 dark:from-color_800 dark:to-color_700 text-gray-700 dark:text-gray-300 rounded-lg shadow-soft-xl transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer">
                                            <img src={user.imageData.data === 'img/avatar.jpeg' ? '/img/avatar.jpeg' : user.imageData.data} alt={user.username} className="rounded-lg" />
                                        </div>
                                    </td>
                                    <td className="p-3 text-gray-700 dark:text-gray-100 font-medium rounded-r-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                        <Link to='/profile' className="block">
                                            {user.name}<br />
                                            <span className="text-xs">{user.email}</span>
                                        </Link>
                                    </td>
                                </tr>
                                <tr className="group text-sm">
                                    <td className="w-12 text-center py-3 px-2 rounded-l-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                        <div className="flex items-center justify-center w-8 h-8 bg-gradient-to-tl from-color_50 to-color_50 dark:from-color_800 dark:to-color_700 text-gray-700 dark:text-gray-300 rounded-lg shadow-soft-xl transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer">
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} />
                                        </div>
                                    </td>
                                    <td className="p-3 text-gray-700 dark:text-gray-100 font-medium rounded-r-lg group-hover:bg-color_50 dark:group-hover:bg-color_900">
                                        <button onClick={logout}>
                                            Logout
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </aside>
        </React.Fragment>
    )
}

export default Sidebar