import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from '../context/AuthContext.js';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faEye, faRotate, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Loading } from "./Loading.jsx";
import AlertError from "./alert/AlertError.jsx";
import AlertSucess from "./alert/ALertSuccess.jsx";

const whatsapp = process.env.REACT_APP_WHATSAPP;
const instagram = process.env.REACT_APP_INSTAGRAM;
const github = process.env.REACT_APP_GITHUB;

const Docs = ({ axiosInstance }) => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visitors, setVisitorData] = useState(0);
    const [users, setTotalUsers] = useState(0);
    const [totalReq, setTotalReq] = useState(0);
    const [requests, setRequestToday] = useState(0);
    const [dateTimeInfo, setDateTimeInfo] = useState({
        day: '',
        month: '',
        year: '',
        hours: '',
        minutes: '',
        seconds: ''
    });
    const [apikey, setApikey] = useState('');
    const { user, logout, checkApikey } = useContext(AuthContext);

    const handleChange = (e) => {
        setApikey(e.target.value);
    };

    const useCountdown = (targetTime) => {
        const [countdown, setCountdown] = useState(targetTime - Date.now());

        useEffect(() => {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    const newCountdown = prevCountdown - 1000;
                    if (newCountdown <= 0) {
                        clearInterval(timer);
                        return 0;
                    }
                    return newCountdown;
                });
            }, 1000);

            return () => clearInterval(timer);
        }, [targetTime]);

        return countdown;
    };
    const tokenExpiration = new Date(user.tokenExpiration).getTime();
    const adjustedTokenExpiration = tokenExpiration - 5000;
    const countdown = useCountdown(adjustedTokenExpiration);

    useEffect(() => {
        if (countdown <= 0) {
            logout();
        }
    }, [countdown, logout]);

    const minutes = Math.floor((countdown / (1000 * 60)) % 60);
    const seconds = Math.floor((countdown / 1000) % 60);

    useEffect(() => {
        const getCurrentDateTime = () => {
            const jakartaTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
            const jakartaDateTime = new Date(jakartaTime);

            const day = jakartaDateTime.getDate();
            const month = jakartaDateTime.getMonth() + 1;
            const year = jakartaDateTime.getFullYear();
            const hours = jakartaDateTime.getHours();
            const minutes = jakartaDateTime.getMinutes();
            const seconds = jakartaDateTime.getSeconds();
            setDateTimeInfo({ day, month, year, hours, minutes, seconds });
        };
        getCurrentDateTime();
        const interval = setInterval(getCurrentDateTime, 1000);
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const getStats = async () => {
            try {
                const response = await axiosInstance.get('/dashboard/');

                const { totalVisitors, totalRequestsToday, totalUsers, totalRequests } = response.data
                setTotalReq(totalRequests);
                setRequestToday(totalRequestsToday);
                setVisitorData(totalVisitors);
                setTotalUsers(totalUsers)
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        getStats();
    }, [axiosInstance]);

    const handleSubmitApikey = async (e) => {
        e.preventDefault();
        try {
            const result = await checkApikey(apikey);
            setSuccessMessage(result);
            setIsSuccess(true);
            setApikey('');
        } catch (error) {
            setErrorMessage('Apikey Notfound');
            setIsError(true);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setIsSuccess(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
        if (isError) {
            const timer = setTimeout(() => {
                setErrorMessage('');
                setIsError(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isSuccess, isError]);

    const columns = [
        {
            title: "Visitors",
            value: visitors,
            change: "+50%",
            icon: faEye,
            iconBg: "from-color_400 to-color_500 dark:from-color_800 dark:to-color_600",
            iconColor: "text-white"
        },
        {
            title: "Total Users",
            value: users,
            change: "+30%",
            icon: faUsers,
            iconBg: "from-blue-400 to-blue-500 dark:from-blue-600 dark:to-blue-800",
            iconColor: "text-white"
        },
        {
            title: "Total Requests",
            value: totalReq,
            change: "-20%",
            icon: faRotate,
            iconBg: "from-green-400 to-green-500 dark:from-green-600 dark:to-green-800",
            iconColor: "text-white"
        },
        {
            title: "Request Today",
            value: requests,
            change: "+10%",
            icon: faCalendarDay,
            iconBg: "from-yellow-400 to-yellow-500 dark:from-yellow-600 dark:to-yellow-800",
            iconColor: "text-white"
        }
    ];

    return (
        <React.Fragment>
            {loading && (<Loading load={loading} />)}
            {isSuccess && (<AlertSucess message={successMessage} />)}
            {isError && (<AlertError message={errorMessage} />)}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {columns.map((col, index) => (
                    <div key={index} className="bg-color_50 dark:bg-color_900 p-4 rounded-lg shadow-soft-2xl">
                        <div className="flex justify-between items-center">
                            <div>
                                <h1 className="text-gray-600 dark:text-gray-200 font-semibold">{col.title}</h1>
                                <p className="text-xl text-gray-700 dark:text-gray-100">
                                    {col.value}
                                </p>
                            </div>
                            <div className="text-xl">
                                <div className={`flex items-center justify-center w-12 h-12 bg-gradient-to-tl ${col.iconBg} ${col.iconColor} rounded-lg shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer`}>
                                    <FontAwesomeIcon icon={col.icon} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="my-4 p-4 text-gray-700 dark:text-gray-300 bg-color_50 dark:bg-color_900 rounded-lg shadow-soft-2xl">
                <h2 className="text-lg mb-4">Current Date and Time</h2>
                <div className="flex">
                    <span id="jam" className="mx-1 text-red-500 dark:text-gray-200">{dateTimeInfo.hours}</span> :
                    <span id="menit" className="mx-1 text-red-500 dark:text-gray-200">{dateTimeInfo.minutes}</span> :
                    <span id="detik" className="mx-1 mr-4 text-red-500 dark:text-gray-200">{dateTimeInfo.seconds}</span>
                    <span id="tgl" className="mr-1">{dateTimeInfo.day}</span> -
                    <span id="bln" className="mx-1">{dateTimeInfo.month}</span> -
                    <span id="thn" className="mx-1">{dateTimeInfo.year}</span> {' '}
                </div>
            </div>

            <div className="my-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="p-4 text-gray-700 dark:text-gray-300 bg-color_50 dark:bg-color_900 rounded-md shadow-soft-2xl">
                    <p className="mt-2 mb-5 text-lg">You will be logout automatically</p>
                    <div className="mt-5">
                        <span className="text-red-500 dark:text-gray-200" id="menit">{minutes}</span> Minutes{' '}
                        <span className="text-red-500 dark:text-gray-200" id="detik">{seconds}</span> Seconds
                    </div>
                </div>
                <div className="p-4 text-gray-700 dark:text-gray-300 bg-color_50 dark:bg-color_900 rounded-md shadow-soft-2xl">
                    <p className="mt-2 mb-0 text-lg">Check Apikey</p>
                    <form onSubmit={handleSubmitApikey} className="mt-4">
                        <div className="mb-1 grid sm:grid-cols-1 md:grid-cols-4 gap-1">
                            <input
                                type="text"
                                id="apikey"
                                name="apikey"
                                value={apikey}
                                onChange={handleChange}
                                className="mt-1 p-2 border bg-color_50 dark:bg-color_900 rounded-md col-span-3 focus:outline-none"
                                placeholder="Input Apikey"
                                required
                            />
                            <button
                                type="submit"
                                className="text-sm flex items-center justify-center px-4 py-2 bg-gradient-to-tl from-color_400 to-color_500 dark:from-color_800 dark:to-color_700 text-white rounded-md shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                    <p id="check-result" className="mt-4"></p>
                </div>
            </div>

            <div className="mt-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 grid-rows-1 gap-4">
                <div className="col-span-1 bg-color_50 rounded-md shadow-md text-gray-700 dark:bg-color_900 dark:text-gray-300 p-4">
                    <p className="text-lg">Thanks To</p>
                    <div className="px-2 grid md:grid-cols-2 gap-1 sm:grid-cols-1">
                        <ul className="ml-4">
                            <li className="list-disc">Allah SWT</li>
                            <li className="list-disc">My Friend</li>
                            <li className="list-disc">Ameliascrf</li>
                            <li className="list-disc">Alex</li>
                        </ul>
                        <ul className="ml-4">
                            <li className="list-disc">And all of you who have used the web wisely and always
                                supported me.</li>
                        </ul>
                    </div>
                </div>
                <div className="col-span-1 bg-color_50 rounded-md shadow-md text-gray-700 dark:bg-color_900 dark:text-gray-300 p-4 h-auto">
                    <p className="text-lg">Contact Me</p>
                    <div className="px-2">
                        <ul>
                            <li className="py-1">
                                <Link to={github} target="_blank">
                                    <FontAwesomeIcon icon={faGithub} />{' '}
                                    Github
                                </Link>
                            </li>
                            <li className="py-1 hover:text-green-500">
                                <Link to={whatsapp} target="_blank">
                                    <FontAwesomeIcon icon={faWhatsapp} />{' '}
                                    WhatsApp
                                </Link>
                            </li>
                            <li className="py-1 hover:text-pink-500">
                                <Link to={instagram} target="_blank">
                                    <FontAwesomeIcon icon={faInstagram} />{' '}
                                    Instagram
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Docs;
