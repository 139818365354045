import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faList } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ toggleDarkMode, toggleSidebar, darkMode, title }) => {
    const { features, category } = useParams();
    function capitalize(str) {
        if (typeof str !== 'string') return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <React.Fragment>
            <header className={`text-gray-700 dark:text-gray-300 p-4 flex justify-between items-center`}>
                <div>
                    <nav className="w-full rounded-md text-sm">
                        <ul className="list-reset flex gap-1">
                            <li>
                                <Link
                                    to="/"
                                    className="text-color_500 dark:text-gray-400 transition duration-150 ease-in-out hover:text-primary-accent-300 focus:text-primary-accent-300 active:text-primary-accent-300 motion-reduce:transition-none dark:text-primary-400 font-light"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <span className="text-gray-500">/</span>
                            </li>
                            <li className="text-gray-700 dark:text-gray-300 flex gap-1">
                                {features && category ? (
                                    <>
                                        <Link
                                            to={`/${features}`}
                                            className="text-color_500 dark:text-gray-500 transition duration-150 ease-in-out hover:text-primary-accent-300 focus:text-primary-accent-300 active:text-primary-accent-300 motion-reduce:transition-none dark:text-primary-400 font-light"
                                        >
                                            {capitalize(features)}
                                        </Link>
                                        <span className="text-gray-500">/</span>
                                        <span>{capitalize(category)}</span>
                                    </>
                                ) : (
                                    <span>{category ? capitalize(category) : capitalize(title)}</span>
                                )}
                            </li>
                        </ul>
                    </nav>
                    <p className="font-semibold text-gray-700 dark:text-gray-300">
                        {features && category
                            ? capitalize(category)
                            : features
                                ? capitalize(features)
                                : capitalize(title)}
                    </p>
                </div>
                <div className="flex gap-2 items-center">
                    <form className="hidden md:flex items-center bg-color_100 dark:bg-color_1000 rounded-md">
                        <div className="relative flex items-center">
                            <button type="submit" className={`absolute right-0 bg-primary-accent-300 dark:text-white text-gray-700 px-3 py-1 rounded-md hover:bg-primary-accent-400 focus:outline-none focus:bg-primary-accent-400`}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                            <input type="text" placeholder="Type here..." className="py-1 px-2 focus:outline-none focus:ring-2 focus:ring-primary-accent-300 border border-gray-400 dark:border-color_800 rounded-md dark:text-gray-200 bg-color_100 dark:bg-color_900" />
                        </div>
                    </form>
                    <label
                        htmlFor="AcceptConditions"
                        className="relative inline-block h-8 w-14 cursor-pointer rounded-full bg-gray-50 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-color_900"
                    >
                        <input type="checkbox" id="AcceptConditions" checked={darkMode} onChange={toggleDarkMode} className="peer sr-only" />
                        <span
                            className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-gray-50 ring-[6px] ring-inset ring-gray-500 transition-all peer-checked:start-8 peer-checked:w-2 peer-checked:bg-white peer-checked:ring-transparent"
                        >
                        </span>
                    </label>
                    <button onClick={toggleSidebar} type="submit" className="block lg:hidden w-10 text-md h-10 bg-gradient-to-tl from-color_50 to-color_50 text-gray-700 dark:text-gray-50 dark:from-color_800 dark:to-color_700 rounded-lg shadow-md transform transition-all duration-300 group-hover:scale-105 group-hover:shadow-lg cursor-pointer">
                        <FontAwesomeIcon icon={faList} />
                    </button>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Navbar