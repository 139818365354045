import React from "react";

const AlertSucess = ({ message }) => {
    return (
        <React.Fragment>
            <div role="alert" className="fixed top-4 right-4 z-50 shadow-soft-3xl">
                <div className="rounded-xl border border-gray-100 dark:border-gray-900 dark:bg-color_900 bg-color_50 p-4 flex items-start gap-4">
                    <span className="text-green-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </span>

                    <div className="flex-1">
                        <strong className="block font-medium text-gray-800 dark:text-gray-300">Success</strong>

                        <p className="mt-1 text-sm text-gray-700 dark:text-gray-300">{message}</p>
                    </div>

                    <button className="text-gray-700 transition hover:text-gray-800 dark:text-gray-300">
                        <span className="sr-only">Dismiss popup</span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AlertSucess;
