import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FeatureTable from './FeatureTable.jsx';
import { AuthContext } from '../../context/AuthContext.js';
import { FeaturesContext } from '../../context/FeatureContext.js';

import { Loading } from '../Loading.jsx';
import AlertError from '../alert/AlertError.jsx';

function useAuthAndFeatures() {
    const { token } = useContext(AuthContext);
    const { fetchAllFeatures } = useContext(FeaturesContext);

    return { token, fetchAllFeatures };
}

const Details = () => {
    const { token, fetchAllFeatures } = useAuthAndFeatures();
    const { category } = useParams();
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFeatures = async () => {
            if (!token || !fetchAllFeatures) {
                setError('Context values not available.');
                setLoading(false);
                return;
            }

            try {
                const data = await fetchAllFeatures(token);
                const filteredFeatures = data.filter(f => f.category.replace(" ", "-").toLowerCase() === category);
                setFeatures(filteredFeatures);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        loadFeatures();
    }, [token, category, fetchAllFeatures]);

    if (loading) return <Loading />;
    if (error) return <AlertError message={error} />;

    return (
        <React.Fragment>
            <FeatureTable features={features} />
        </React.Fragment>
    );
};

export default Details;
