import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout.jsx';
import FeaturesAll from './All.jsx';
import { FeaturesProvider } from '../../context/FeatureContext.js';
import NotFound from '../site/NotFound.jsx'
import Details from './Details.jsx';

function RouterFeature() {
    return (
        <FeaturesProvider>
            <Routes>
                <Route path="/" element={<Layout title="Features"><div className='text-gray-700 dark:text-gray-300'>Please select a feature. </div></Layout>} />
                <Route path="/all" element={<Layout title="All Features"><FeaturesAll /></Layout>} />
                <Route path="/:category" element={<Layout title=":category"><Details /></Layout>} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </FeaturesProvider>
    );
}

export default RouterFeature;
