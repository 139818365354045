import React from "react";
import { Link } from 'react-router-dom';

const whatsapp = process.env.REACT_APP_WHATSAPP;
const instagram = process.env.REACT_APP_INSTAGRAM;
const github = process.env.REACT_APP_GITHUB;

const Footer = () => {
    return (
        <footer className='py-6'>
            <div className='container mx-auto px-4'>
                <div className='flex flex-col md:flex-row justify-between items-center text-gray-900'>
                    <div className='flex gap-4 mb-4 md:mb-0'>
                        <Link to={whatsapp} className="flex items-center gap-2 hover:text-color_500">
                            <img src="logo/whatsapp.png" alt="WhatsApp logo" className="w-5 h-5" /> WhatsApp
                        </Link>
                        <Link to={instagram} className="flex items-center gap-2 hover:text-color_500">
                            <img src="logo/instagram.png" alt="Instagram logo" className="w-5 h-5" /> Instagram
                        </Link>
                        <Link to={github} className="flex items-center gap-2 hover:text-color_500">
                            <img src="logo/github.png" alt="Github logo" className="w-5 h-5" /> Github
                        </Link>
                    </div>
                    <div className='text-center md:text-right'>
                        <p className='text-sm'>
                            Copyright &copy; 2024 by <Link to={instagram} className='text-color_500 hover:underline'>{instagram.split('/')[instagram.split('/').length - 1]}</Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
