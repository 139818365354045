import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from './partial/Header';
import Footer from '../partial/Footer';
import { Loading } from '../Loading';
import AlertModal from '../alert/AlertModal';
import { AuthContext } from '../../context/AuthContext';

const allowedDomains = process.env.REACT_APP_ALLOWED_DOMAINS.split(',');

const Signup = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [messageError, setMessageError] = useState('');

    const navigate = useNavigate();
    const { signup } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const domain = email.split('@')[1];

            if (!allowedDomains.includes(domain)) {
                setMessageError('Email domain is not allowed!');
                setEmail('');
                setLoading(false);
                handleShowModal();
                return;
            }

            if (password !== passwordConfirmation) {
                setMessageError('Passwords do not match!');
                setLoading(false);
                handleShowModal();
                return;
            }

            await signup(email, phone, password, name, username);
            setLoading(false);
            navigate('/login');
        } catch (error) {
            setLoading(false);
            setMessageError(error.message);
            setShowModal(true);
        }
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setMessageError('');
    };

    return (
        <div className='flex flex-col min-h-screen lg:mx-40'>
            {showModal && <AlertModal onClose={handleCloseModal} message={messageError} />}
            {loading && <Loading />}
            <Header />
            <div className="flex-grow flex items-center justify-center">
                <div className="max-w-md w-72 md:w-96 bg-color_50 p-8 rounded-lg shadow-xl">
                    <h2 className="text-2xl mb-4 text-center font-semibold text-color_500">Sign Up</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Full Name"
                            required
                        />
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Username"
                            required
                        />
                        <input
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Phone Number"
                            required
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Email"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Password"
                            required
                        />
                        <input
                            type="password"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Password Confirmation"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-color_500 text-white py-2 px-4 rounded hover:bg-color_400 focus:outline-none focus:bg-color_400"
                        >
                            Sign Up
                        </button>
                    </form>
                    <p className="mt-4 text-center">
                        Already have an account? <Link to="/login" className="text-color_500">Log in</Link>
                    </p>
                </div>
            </div>
            <Footer className="self-end" />
        </div>
    );
};

export default Signup;
