import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './partial/Header';
import Footer from '../partial/Footer';
import { Loading } from '../Loading';
import AlertModal from '../alert/AlertModal';
import { AuthContext } from '../../context/AuthContext';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [messageError, setMessageError] = useState('');

    const { login } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await login(username, password);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setMessageError(error.message);
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setMessageError('');
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div className='flex flex-col min-h-screen lg:mx-40'>
            {showModal && <AlertModal onClose={handleCloseModal} message={messageError} />}
            {loading && <Loading />}
            <Header />
            <div className="flex-grow flex items-center justify-center">
                <div className="max-w-md w-72 md:w-96 bg-color_50 p-8 rounded-lg shadow-xl">
                    <h2 className="text-2xl mb-4 text-center font-semibold text-color_500">Login</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Username"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full mb-4 p-2 rounded border border-gray-300 focus:outline-none focus:border-color_500"
                            placeholder="Password"
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-color_500 text-white py-2 px-4 rounded hover:bg-color_400 focus:outline-none focus:bg-color_400"
                        >
                            Login
                        </button>
                    </form>
                    <p className="mt-4 text-center">
                        Don't have an account? <Link to="/signup" className="text-color_500">Sign Up</Link>
                    </p>
                </div>
            </div>
            <Footer className="self-end" />
        </div>
    );
};

export default Login;
