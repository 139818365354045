import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import { FeaturesContext } from '../../context/FeatureContext.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { Navigate } from 'react-router-dom';

const domain = process.env.REACT_APP_DOMAIN;

const FeaturesAll = () => {
    const { token, user } = useContext(AuthContext);
    const { fetchAllFeatures } = useContext(FeaturesContext);

    const [currentPage, setCurrentPage] = useState(0);
    const [features, setFeatures] = useState([]);
    const [filteredFeatures, setFilteredFeatures] = useState([]);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    useEffect(() => {
        const getFeatures = async () => {
            try {
                const data = await fetchAllFeatures(token);
                setFeatures(data);
                setFilteredFeatures(data);
            } catch (error) {
                setError(error.message);
            }
        };

        if (token) {
            getFeatures();
        }
    }, [fetchAllFeatures, token]);

    const rowsPerPage = 10;
    const offset = currentPage * rowsPerPage;

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    useEffect(() => {
        const handleAutoSearch = () => {
            if (!features) return;

            const filtered = features.filter(feature =>
                (feature.featureName && feature.featureName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (feature.description && feature.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (feature.category && feature.category.toLowerCase().includes(searchQuery.toLowerCase()))
            );
            setFilteredFeatures(filtered);
        };

        handleAutoSearch();
    }, [searchQuery, features]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredFeatures(features);
        }
    }, [searchQuery, features]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (!features) return;

        const filteredFeatures = features.filter(feature =>
            (feature.featureName && feature.featureName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (feature.description && feature.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (feature.category && feature.category.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredFeatures(filteredFeatures);
    };

    const pageCount = Math.ceil(filteredFeatures.length / rowsPerPage);
    const displayedFeatures = filteredFeatures.slice(offset, offset + rowsPerPage);

    if (error) {
        return (<Navigate to='/login' />)
    }

    const requestMethodClasses = {
        GET: 'bg-green-500 text-gray-100',
        POST: 'bg-blue-700 text-blue-100',
        PUT: 'bg-yellow-700 text-yellow-100',
        DELETE: 'bg-red-700 text-red-100',
        PATCH: 'bg-purple-700 text-purple-100'
    };

    const RequestMethodBadge = ({ method }) => {
        const classNames = `px-2 py-1 text-xs rounded mt-1 ${requestMethodClasses[method]}`;
        return <span className={classNames}>{method}</span>;
    };

    const sortFeatures = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const sortedFeatures = [...features].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setSortConfig({ key, direction });
        setFeatures(sortedFeatures);
    };

    return (
        <React.Fragment>
            <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-color_50 dark:bg-color_900 border-0 border-transparent border-solid shadow-soft-xl rounded-lg bg-clip-border">
                <div className="p-6 pb-0 mb-0 rounded-t-2xl text-gray-700 dark:text-gray-300 flex items-center">
                    <form className="flex items-center rounded-md" onSubmit={handleSearch}>
                        <div className="relative flex items-center">
                            <button type="submit" className={`absolute right-0 bg-primary-accent-300 dark:text-white text-gray-700 px-3 py-1 rounded-md hover:bg-primary-accent-200 focus:outline-none focus:bg-primary-accent-400`}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                            <input
                                type="text"
                                placeholder="Search..."
                                className="py-1 px-2 focus:outline-none focus:ring-2 focus:ring-primary-accent-300 border border-gray-300 rounded-md dark:text-gray-200 bg-color_100 dark:bg-color_900"
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }}
                            />
                        </div>
                    </form>
                </div>
                <div className="flex-auto px-0 pt-0 pb-2">
                    <div className="p-0 px-4 sm:px-2 md:px-0">
                        <div className='overflow-x-auto'>
                            <table className="items-center w-full mb-0 align-top border-gray-200  text-gray-600 dark:text-gray-400 table-striped">
                                <thead className="align-bottom font-light">
                                    <tr>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">No</th>
                                        <th onClick={() => sortFeatures('featureName')} className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Feature Name</th>
                                        <th onClick={() => sortFeatures('category')} className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Category</th>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Request Method</th>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Description</th>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Query Parameter</th>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Status</th>
                                        <th className="px-6 py-3 font-medium text-center uppercase align-middle bg-transparent shadow-none text-xs tracking-none ">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedFeatures.map((feature, index) => (
                                        <tr key={index}>
                                            <td className="p-2 align-middle bg-transparent text-sm shadow-transparent text-center">
                                                <span className="leading-tight text-sm">{index + 1}</span>
                                            </td>
                                            <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                                <span className="leading-tight text-sm">{feature.featureName}</span>
                                            </td>
                                            <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                                <span className="leading-tighttext-sm">{feature.category}</span>
                                            </td>
                                            <td className="text-sm flex justify-center items-center">
                                                <div className="flex flex-wrap gap-2">
                                                    {feature.requestMethod.slice(0, 2).map((method) => (
                                                        <RequestMethodBadge key={method} method={method} />
                                                    ))}
                                                </div>
                                                {feature.requestMethod.length > 2 && (
                                                    <div className="flex flex-wrap gap-2 mt-2">
                                                        {feature.requestMethod.slice(2).map((method) => (
                                                            <RequestMethodBadge key={method} method={method} />
                                                        ))}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                                <span className="leading-tighttext-sm">{feature.description}</span>
                                            </td>
                                            <td className="p-2 align-middle bg-transparent text-sm shadow-transparent">
                                                <span className="leading-tighttext-sm">{feature.queryParameter}</span>
                                            </td>
                                            <td className="text-center p-2 align-middle bg-transparent text-sm shadow-transparent">
                                                <span className={`px-3 py-1 text-xs rounded ${feature.status.toLowerCase() === 'active' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                                    {feature.status}
                                                </span>
                                            </td>
                                            <td className="text-center flex justify-center gap-2 text-xs items-center p-2 text-gray-100">
                                                {feature.status === 'active' ? (
                                                    <Link to={`${domain}/api/${feature.url.replace("YOUR_APIKEY", user.apiKey)}`} className="px-2 py-1 rounded-lg shadow-soft-2xl bg-green-500">
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </Link>
                                                ) : (
                                                    <button type='button' className="px-2 py-1 rounded-lg shadow-soft-2xl bg-red-500 text-white" disabled>
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination flex justify-center items-center space-x-2 font-md'}
                            pageClassName={'px-3 py-1 rounded-lg cursor-pointer text-gray-700 dark:text-gray-100'}
                            previousClassName={'px-3 py-1 rounded-md cursor-pointer hover:bg-gray-300 dark:hover:bg-color_800 text-gray-700 dark:text-gray-300'}
                            nextClassName={'px-3 py-1 rounded-md cursor-pointer hover:bg-gray-300 dark:hover:bg-color_800 text-gray-700 dark:text-gray-300'}
                            breakClassName={'px-3 py-1 rounded-md cursor-pointer'}
                            activeClassName={'bg-color_500 dark:bg-color_800 text-white'}
                            disabledClassName={'text-gray-300 cursor-not-allowed hover:bg-color_50 dark:hover:bg-color_800'}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

export default FeaturesAll;
