import React, { createContext } from 'react';
import axiosInstance from '../axiosInstance';

const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
    const fetchAllFeatures = async (token) => {
        try {
            const response = await axiosInstance.get(`/features`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            throw new Error(`Failed to fetch all features. ${error.message}`);
        }
    };

    const getFeatureById = async (token, id) => {
        try {
            const response = await axiosInstance.get(`/features/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200 && response.data) {
                return response.data;
            } else {
                throw new Error('Failed to add feature. No data received from server.');
            }
        } catch (error) {
            throw new Error(`Failed to add feature. ${error.message}`);
        }
    };

    return (
        <FeaturesContext.Provider value={{ fetchAllFeatures, getFeatureById }}>
            {children}
        </FeaturesContext.Provider>
    );
};

export { FeaturesContext };
