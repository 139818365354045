import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Footer from './partial/Footer';
import Header from './partial/Header';
import { Loading } from '../Loading';
const whatsapp = process.env.REACT_APP_WHATSAPP;

const Home = ({ axiosInstance }) => {
    const [loading, setLoading] = useState(true);
    const [visitorData, setVisitorData] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalReq, setTotalReq] = useState(0);
    const [requestToday, setRequestToday] = useState(0);

    useEffect(() => {
        const getStats = async () => {
            try {
                const response = await axiosInstance.get('/dashboard');
                const { totalVisitors, totalRequestsToday, totalUsers, totalRequests } = response.data;
                setTotalReq(totalRequests);
                setRequestToday(totalRequestsToday);
                setVisitorData(totalVisitors);
                setTotalUsers(totalUsers);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        getStats();
    }, [axiosInstance]);

    useEffect(() => {
        const handleScrollToElement = () => {
            if (window.location.hash) {
                const element = document.querySelector(window.location.hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        handleScrollToElement();

        const handleInternalNavigation = () => {
            handleScrollToElement();
        };

        window.addEventListener('hashchange', handleInternalNavigation);

        return () => {
            window.removeEventListener('hashchange', handleInternalNavigation);
        };
    }, []);

    function splitNumber(num) {
        if (num <= 999) {
            return num.toString();
        } else if (num <= 999999) {
            return (num / 1000).toFixed(1) + 'K+';
        } else {
            return (num / 1000000).toFixed(1) + 'M+';
        }
    }

    return (
        <div className="lg:mx-40 text-gray-900 overflow-y-auto">
            {loading && (<Loading load={loading} />)}
            <Header />
            <main>
                <div id="home" className='md:min-h-screen flex flex-col md:flex-row items-center justify-center p-4'>
                    <div className='flex flex-col justify-center items-center md:items-start md:w-1/2'>
                        <h1 className='text-color_500 text-4xl md:text-6xl font-semibold text-center md:text-left'>Dobda <br />Rest APIs</h1>
                        <h3 className='font-semibold my-4 text-center md:text-left'>
                            Provides various API needs such as downloader, converter, image processing, image creator, game username checker for web development, bot development, etc.
                        </h3>
                        <div className='flex justify-center md:justify-start gap-4'>
                            <Link to="#pricing" className='border-2 px-4 py-2 rounded-md border-color_500 text-color_500 hover:bg-color_500 hover:text-color_50 hover:border-color_300 transition-all ease-in duration-300'>Buy Now</Link>
                            <Link to="/docs" className='px-4 py-2 border-2 rounded-md border-color_300 hover:border-color_500 bg-color_500 text-color_50 hover:bg-transparent transition-all ease-in duration-300 hover:text-gray-900'>Documentation</Link>
                        </div>
                    </div>
                    <div className='flex justify-center items-center md:w-1/2 mt-8 md:mt-0'>
                        <img
                            src='img/home.png'
                            alt="Hero"
                            className="max-w-full h-auto"
                        />
                    </div>
                </div>

                <div id="pricing" className='md:min-h-screen'>
                    <div className='text-center'>
                        <h1 className='font-semibold text-4xl text-color_500'>Ready to get started?</h1>
                        <h3 className='font-semibold text-xl text-color_400'>Choose a plan that suits your needs</h3>
                    </div>
                    <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-3 my-8 mx-8'>
                        <div className='border rounded-md border-color_500 p-4'>
                            <div className='text-center bg-color_500 text-color_50 rounded-md p-2'>
                                <h1 className='font-semibold text-xl'>Free</h1>
                                <h2 className='font-semibold'>Rp. 0,-</h2>
                            </div>
                            <div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Request</p>
                                    <p>10/day</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Custom Api Key</p>
                                    <p>No</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Premium Features</p>
                                    <p>No</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>VIP Features</p>
                                    <p>No</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Expired</p>
                                    <p>-</p>
                                </div>
                            </div>
                            <div className='flex justify-center m-4'>
                                <p><Link to="/signup" className='text-color_50 font-semibold bg-color_500 px-6 py-2 rounded-md'>Sign Up</Link></p>
                            </div>
                        </div>
                        <div className='border rounded-md border-color_500 p-4'>
                            <div className='text-center bg-color_500 text-color_50 rounded-md p-2'>
                                <h1 className='font-semibold text-xl'>Premium</h1>
                                <h2 className='font-semibold'>Rp. 10.000,-</h2>
                            </div>
                            <div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Request</p>
                                    <p>100/day</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Custom Api Key</p>
                                    <p>Yes</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Premium Features</p>
                                    <p>Yes</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>VIP Features</p>
                                    <p>No</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Expired</p>
                                    <p>1 Month</p>
                                </div>
                            </div>
                            <div className='flex justify-center m-4'>
                                <p><Link to={whatsapp} className='text-color_50 font-semibold bg-color_500 px-6 py-2 rounded-md'>Order via WhatsApp</Link></p>
                            </div>
                        </div>
                        <div className='border rounded-md border-color_500 p-4'>
                            <div className='text-center bg-color_500 text-color_50 rounded-md p-2'>
                                <h1 className='font-semibold text-xl'>VIP</h1>
                                <h2 className='font-semibold'>Rp. 25.000,-</h2>
                            </div>
                            <div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Request</p>
                                    <p>Unlimited</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Custom Api Key</p>
                                    <p>Yes</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Premium Features</p>
                                    <p>Yes</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>VIP Features</p>
                                    <p>Yes</p>
                                </div>
                                <div className='flex justify-between border-b py-4 border-color_500'>
                                    <p>Expired</p>
                                    <p>1 Month</p>
                                </div>
                            </div>
                            <div className='flex justify-center m-4'>
                                <p><Link to={whatsapp} className='text-color_50 font-semibold bg-color_500 px-6 py-2 rounded-md'>Order via WhatsApp</Link></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="about" className='md:min-h-screen flex items-center px-4'>
                    <div className='grid gap-4 md:grid-cols-2 md:grid-rows-1 w-full'>
                        <div className='flex flex-col items-center justify-center gap-4 font-semibold text-6xl'>
                            <div className='flex justify-center text-center items-center gap-4 lg:my-4'>
                                <div>
                                    <h1 className='text-color_500'>{splitNumber(requestToday)}</h1>
                                    <p className='text-sm'>Request</p>
                                </div>
                                <div>
                                    <h1 className='text-color_500'>{splitNumber(totalUsers)}</h1>
                                    <p className='text-sm'>Users</p>
                                </div>
                            </div>
                            <div className='flex justify-center text-center gap-4 lg:my-4'>
                                <div>
                                    <h1 className='text-color_500'>{splitNumber(totalReq)}</h1>
                                    <p className='text-sm'>Total Request</p>
                                </div>
                                <div>
                                    <h1 className='text-color_500'>{splitNumber(visitorData)}</h1>
                                    <p className='text-sm'>Visitor</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='mb-8'>
                                <h1 className='font-semibold text-2xl md:text-4xl text-color_500'>What is an API?</h1>
                                <p className='text-lg md:text-xl my-4'>APIs are mechanisms that enable two software components to communicate with each other using a set of definitions and protocols.</p>
                            </div>
                            <div>
                                <h1 className='font-semibold text-2xl md:text-4xl text-color_500'>How do APIs work?</h1>
                                <p className='text-lg md:text-xl my-4'>API architecture is usually explained in terms of client and server. The application sending the request is called the client, and the application sending the response is called the server.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default Home;